'use strict';

angular.module('enervexSalesappApp').controller('JobDetailCtrl', function($scope, $stateParams, $state, Job, $filter, JobService, TodolistsService, DiscussionsService, User, Discussion, Design, Quote, Attachment, Activity, Document, Auth, Country, LocationData, _, Specification, Property, Util) {

	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.isAccountOwner = Auth.isAccountOwner;
	$scope.configs = Property.configs()

	var idParams = {
		accountId: $stateParams.accountId,
		jobId: $stateParams.jobId
	}
	if ($stateParams.jobId != 'new') {
		JobService.getJob().$promise.then(function(job){
			$scope.job = job;
			if (!$scope.job.address) {
				$scope.job.address = {}
			}
			$scope.job.address.state =  job.address.state && job.address.state._id
			getState()
			$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs)
			if (!job.status) {
				job.status = "undefined";
			}

			if (job.status != "undefined") {
				$scope.jobStatus = job.status.substate;
			} else {
				$scope.jobStatus = "undefined";
			}

			$scope.isInternalMember = Auth.isInternalMember($scope.job);
			$scope.members = [];

			_.each(job.members,function(member){
				$scope.members.push(member.user);
			});
		});
		$scope.discussions = Discussion.query(idParams);
		$scope.designs = Design.query(idParams);
		$scope.quotes = Quote.query(idParams);
		$scope.attachments = Attachment.query(idParams);
		Activity.query(idParams).$promise.then(function(activities){
			$scope.activities = activities;
		});
		$scope.documents = Document.query(idParams);
		$scope.specifications = Specification.query(idParams);
		fetchTodolists();
		getDiscussions();
	} else {
		var user = Auth.getCurrentUser()

		var preparedBy = null
		if (user && user.name) {
			preparedBy = user.name.first + " " + user.name.last
		}
		$scope.job = {
			preparedBy: preparedBy,
			address: {

			}
		}
	}
	function getState() {
		$scope.state = _.find($scope.states, function(state){
			return state._id == $scope.job.address.state
		})
	}
	JobService.getAccount().$promise.then(function(account){
		$scope.account = account;
		if (!$scope.job._id){
			$scope.job.members = account.members
		}
	});
	JobService.getStates().$promise.then(function(states){
		$scope.states = states;
	});
	JobService.getAccountLocations().$promise.then(function(accountLocations){
		$scope.accountLocations = accountLocations
	});
	Country.query({
		limit:300
	}).$promise.then(function(countries){
		$scope.countries = countries;
	});
	$scope.configs = Property.configs()
	function countryForLocationDataCode(code) {
		var scrubbed = code.replace(".","").replace(" ", "").toUpperCase().substr(0,2);
		var found = _.find($scope.countries, function(country){
			return country.code == scrubbed
		})
		return found;
	}
	$scope.checkEnvironment = function() {
		LocationData.query({
			zip: $scope.job.address.zip,
			state: $scope.job.address.state,
			city: $scope.job.address.city
		}).$promise.then(function(datas){
			var data = (datas && datas.length >0) ? datas[0] : null;
			if (data) {
				$scope.job.environment = data.environment;
				$scope.job.locationData = data;
				$scope.job.address.state = data.state && data.state._id
				$scope.job.address.city = data.city;
				$scope.job.address.zip = data.zip_code;
				$scope.job.address.country = countryForLocationDataCode(data.country)
			}
			getState()
		})
	}
	$scope.clientCompanyChanged = function() {
		if ($scope.job.client.locationData){
			$scope.job.environment = $scope.job.client.locationData.environment;
		} else {
			$scope.job.environment = {}
		}
		$scope.job.address = $scope.job.client.address;
	};
	$scope.jobStatuses = JobService.getStatus();


	$scope.updateJobStatus = function(status) {
		JobService.updateJobStatus(status);
	}
	function fetchTodolists(){
		var todoCount = [];
		TodolistsService.getTodoLists().$promise.then(function(todolists){
			$scope.todolists = todolists;
			_.each(todolists,function(todolist){
				TodolistsService.getAllTodos(todolist).$promise.then(function(todos){
					todolist.todos = todos;
					_.each(todos,function(todo){
						if (todo.status === 'open'){
							todoCount.push(todo);
						}
					});
					$scope.todoSum = todoCount.length;
					_.each(todos,function(todo){
						var dueDate = new Date(todo.assignAt);
						if (todo.assignAt){
							todo.assignAt = dueDate;
							dueDate = $filter('date')(dueDate, 'dd/MM/yyyy');
						}
					});
				});
			})
		});
	}
	$scope.updatesLimit = 5;
	$scope.removeUpdatesLimit = function(){
		$scope.showAllUpdates = true;
		$scope.updatesLimit = $scope.activities.length;
	}
	$scope.placeUpdatesLimit = function(){
		$scope.showAllUpdates = false;
		$scope.updatesLimit = 5;
	}
	$scope.discLimit = 2;
	$scope.removeDiscLimit = function(){
		$scope.discLimit = $scope.discussions.length;
	}
	$scope.placeDiscLimit = function(){
		$scope.discLimit = 2;
	}
	$scope.todoComplete = function(todo){
		if (todo.status === 'open') {
			todo.status = 'closed';
		} else {
			todo.status = 'open';
		}
		TodolistsService.updateTodo(todo).$promise.then(function(res){
			fetchTodolists();
		});
	}
	var externalDiscussions = [];
	var internalDiscussions = [];
	$scope.externalDiscussions = externalDiscussions;
	$scope.internalDiscussions = internalDiscussions;
	function getDiscussions() {
		DiscussionsService.getDiscussions().$promise.then(function(discussions){
			$scope.discussions = discussions;
			_.each(discussions,function(discussion){
				if (discussion.visibility === 'external'){
					externalDiscussions.push(discussion);
				} else {
					internalDiscussions.push(discussion);
				}
			});
		});
	}
	fetchCompanies();
	function fetchCompanies(){
		JobService.getCompanies().$promise.then(function(companies){
			$scope.companies = companies;
		});
	}
	$scope.saveJob = function(){
		var payload = _.clone($scope.job);
		payload = _.omit(payload, "members");
		payload.client = $scope.job.client ? $scope.job.client._id : null
		JobService.createJob(payload).$promise.then(function(res){
			$state.go("job",{
				accountId: $scope.accountId,
				jobId: res._id
			}, {
				reload:true,
				inherit: false
			})
		});
	}
	$scope.todoChange = function (todo){
		Todo.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: todo.todolist,
			id: todo._id
		}, todo);
	}
	$scope.jobPage = true;

});
